
export default {
  props: {
    amount: {
      type: Number,
      default: 0
    }
  },
  methods: {
    makeCurrency(_value) {
      //   console.log("_value", _value);
      let value = typeof _value !== Number ? parseFloat(_value) : _value;

      let output = !value ? "$0.00" : "$" + value.toFixed(2);
      //   console.log("output", output);
      return output;
    }
  }
};
