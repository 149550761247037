
import { mapState } from "vuex";
import Breadcrumbs from "@/components/Breadcrumbs.vue";
import GiftCardFeeModal from "@/components/GiftCardFeeModal.vue";
import PageTitle from "@/components/PageTitle.vue";

export default {
  components: {
    PageTitle,
    GiftCardFeeModal,
    Breadcrumbs
  },
  data() {
    return {
      showGiftCardFeesModal: false
      // lib
    };
  },
  computed: {
    ...mapState("giftCardFees", ["fees"])
  },
  async created() {
    await this.$store.dispatch("giftCardFees/get");
  },
  methods: {
    makeTitle(isLessThan) {
      return isLessThan ? "<=" : "Over";
    },
    save(fees) {
      this.$store.dispatch("giftCardFees/save", fees);

      this.closeModal();
      //Write this to the API, or to the store.
    },
    closeModal() {
      this.showGiftCardFeesModal = false;
    }
  }
};
