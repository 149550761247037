
export default {
  props: ["fees"],
  computed: {
    tempFees() {
      return this.fees.map(item => Object.assign({}, item));
    }
  },
  methods: {
    makeTitle(isLessThan) {
      return isLessThan ? "<=" : "Over";
    },
    save(tempFees) {
      let output = tempFees.map(item => item);
      output.forEach(item => {
        if (typeof item.amount === "string") {
          item.amount = parseFloat(item.amount);
        }
        if (typeof item.fee === "string") {
          item.fee = parseFloat(item.fee);
        }
      });
      this.$emit("save", output);
    }
  }
};
